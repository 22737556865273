import { useEffect, useState } from 'react'
import type { SyncFlowWorkflow } from '../../../../shared/domain/workflows/types.no-deps'
import './CopyWizard'
import {
    Button,
    TextField
} from '@mui/material'
import './App.css'
import { UpdateFlows } from './updateWizard'
import CopyWizard from './CopyWizard'
import Calculation from './Calculation'
import type { accountType, procedureWorkflow, ProcedureSectionProps, SettingsWizardProps, SyncFlowWorkflowWithImage } from '../../types'
import { findElementsOfType, updateElement, getAllSteps, setPref, getPref} from './logic'
import { Step, ConcreteStep, Element } from '../../../../shared/domain/flows/types.no-deps'
import { Expression } from '../../../../shared/domain/flows/Expression.no-deps'
import Markdown from 'react-markdown'
import readmePath from './calculation.readme.md'
import { RenderRow, RenderTile, DisplayModeToggle } from './SharedComponents'
import {SettingsIcon, EyeIcon} from './icons'
type Props = {
    account: accountType
}
function ViewFlows(props: Props) {
    const [message, setMessage] = useState({
        status: 'loading',
        flows: [] as SyncFlowWorkflowWithImage[],
    })
    const [selectedFlow, setFlow] = useState<SyncFlowWorkflow | null>(null)
    const [page, setPage] = useState<'list' | 'copy' | 'settings'>('list')
    const [search, setSearch] = useState<string>('')
    const [viewMode, setViewMode] = useState<'row' | 'tile'>(getPref('viewMode'))
    useEffect(() => {
        fetch('/getFlows?customerId=' + props.account.customerId)
            .then((response) => response.text())
            .then((data) => setMessage(JSON.parse(data)))
    }, [props.account.customerId])
    setPref('viewMode', viewMode);
    if (selectedFlow !== null && page === 'copy') {
        return <CopyWizard selectedAccount={props.account} selectedWorkflow={selectedFlow} />
    }

    if (selectedFlow !== null && page === 'settings') {
        return <div className="ProcedureSection">
            <SettingsWizard selectedAccount={props.account} selectedWorkflow={selectedFlow} />
        </div>
    }

    if (message.status === 'loading')
        return (
            <div className="ViewFlows">
                <div className="pulseLoader"></div>
            </div>
        )

    if (message.status === 'success') {
        var procedureWorkflows = {} as procedureWorkflow
        message.flows.forEach((flow) => {
            if (!flow.procedure) {
                if (!procedureWorkflows[0]) procedureWorkflows[0] = []
                return procedureWorkflows[0].push(flow)
            }

            if (!procedureWorkflows[flow.procedure.id]) procedureWorkflows[flow.procedure.id] = []

            return procedureWorkflows[flow.procedure.id].push(flow)
        })
        return (
            <div className="ViewFlows">
                <div className='row'>
                    <TextField className="search" onChange={(e: any) => { setSearch(e.target.value) }} placeholder="Search" />
                    <DisplayModeToggle viewMode={viewMode} onChange={setViewMode} />
                </div>
                {Object.keys(procedureWorkflows).map(function (key, index) {
                    return (
                        <ProcedureSection
                            account={props.account}
                            setFlow={setFlow}
                            setPage={setPage}
                            procedure={procedureWorkflows[parseInt(key)].filter(flow => {
                                return flow.name.toLowerCase().indexOf(search.toLowerCase()) > -1
                            })}
                            displayMode={viewMode}
                        />
                    )
                })}
            </div>
        )
    }

    return (
        <div className="ViewFlows">
            <div className="Error">
                <h1>⚠️ Error</h1>
                <p>Unfortunately an unknown error has occured when trying to load flows</p>
            </div>
        </div>
    )
}

function ProcedureSection(props: ProcedureSectionProps) {
    if (props.procedure.length === 0)
        return <></>
    var procedureName = <h2>{!props.procedure[0].procedure ? 'Other' : props.procedure[0].procedure.name}</h2>

    return (
        <div className="ProcedureSection">
            {procedureName}
            <ul>
                {props.procedure.map((workflow) => {
                    var actionButtons = []
                    actionButtons.push(<Button
                        onClick={() => {
                            props.setPage('settings')
                            props.setFlow(workflow)
                        }}
                    >
                        <SettingsIcon/>
                    </Button>);
                    if (workflow.procedure) {
                        actionButtons.push(<a
                            rel="noreferrer"
                            href={
                                'https://' +
                                props.account.serverURL +
                                '/procedures/' +
                                workflow.procedure.id +
                                '/tasks/workflows/' +
                                workflow.id +
                                '/edit'
                            }
                            target="_blank"
                        >
                            <Button>
                                <EyeIcon />
                            </Button>
                        </a>)
                    }
                    
                    if (props.displayMode === 'row')
                        actionButtons.push(
                            <Button
                                onClick={() => {
                                    props.setPage('copy')
                                    props.setFlow(workflow)
                                }}
                            >
                                Copy
                            </Button>
                        );
                    return <>{
                        props.displayMode === 'row' ? <RenderRow flow={workflow} buttons={actionButtons} /> : <RenderTile image={workflow.image} buttons={actionButtons} flow={workflow} onClick={() => {
                            props.setPage('copy');
                            props.setFlow(workflow)
                        }} />
                    }</>
                })}
            </ul>
        </div>
    )
}

function SettingsWizard(props: SettingsWizardProps) {
    const [page, setPage] = useState<'list' | 'update'>('list')
    const [selectedWorkflow, setSelectedWorkflow] = useState(props.selectedWorkflow)
    const [attributes, setAttributes] = useState(null as { attributes: string[] } | null)
    const [markdown, setMarkdown] = useState(null as null | string)

    useEffect(() => {
        fetch('/getAttributes?customerId=' + props.selectedAccount.customerId + '&unitType=' + props.selectedWorkflow?.flow?.subjectType?.unitType)
            .then((response) => response.text())
            .then((data) => setAttributes(JSON.parse(data)));

        fetch(readmePath)
            .then(response => {
                return response.text()
            })
            .then(text => {
                setMarkdown(text)
            })
    }, [props.selectedAccount.customerId, props.selectedWorkflow?.flow?.subjectType?.unitType])

    if (!selectedWorkflow.flow || !selectedWorkflow.flow?.steps || !selectedWorkflow.flow?.steps === undefined)
        return <div>Only flows are supported</div>

    if (attributes === null)
        return (
            <div className="ViewFlows">
                <div className="pulseLoader"></div>
            </div>
        )
    if (page === 'update')
        return <UpdateFlows
            instance={props.selectedAccount}
            selectedFlow={{
                customerId: props.selectedAccount.customerId,
                flow: selectedWorkflow
            }}
            updated={() => {

            }} />
    const elements = findElementsOfType(selectedWorkflow.flow?.steps, [], ['calculation', 'number'])
    const steps =
        console.log('steps', getAllSteps(selectedWorkflow.flow.steps, []))
    return (<div>
        {elements.map(element => {
            if (element.type === 'calculation')
                return <>
                    <div className='row header-row'><h2>{element.label}</h2></div>
                    <div className='row'><Calculation expression={element.formula} returnExpression={(expression: Expression, valid: Boolean) => {
                        console.log(expression, valid)
                        if (valid) {
                            element.formula = expression;
                            if (selectedWorkflow.flow)
                                selectedWorkflow.flow.steps = updateElement(selectedWorkflow.flow.steps, element)
                            setSelectedWorkflow(selectedWorkflow)
                        }
                    }} steps={selectedWorkflow.flow ? getAllSteps(selectedWorkflow.flow.steps, []).filter(step => step.type !== 'placeholder') as ConcreteStep[] : []} questions={selectedWorkflow.flow ? findElementsOfType(selectedWorkflow.flow.steps, [], ['yes-no', 'number']) : []} unitAttributes={attributes.attributes} /></div>
                </>
            else
                return <div className='row'>{element.label}<Button onClick={(e) => {
                    const newElement: Element = {
                        type: 'calculation',
                        id: element.id,
                        label: element.label,
                        formula: {
                            type: 'constant',
                            constant: 0
                        },
                        decimalPlaces: 5
                    };
                    if (selectedWorkflow.flow)
                        selectedWorkflow.flow.steps = updateElement(selectedWorkflow.flow.steps, newElement);
                    setSelectedWorkflow(JSON.parse(JSON.stringify(selectedWorkflow)))
                }
                } variant="text">Convert to calculation</Button></div>
        })}
        <Button variant="contained" className='big-button' onClick={() => { setPage('update') }}>Update</Button>
        <div className='documentation'><Markdown>{markdown}</Markdown></div>
    </div>)
}

export default ViewFlows
