import type { SyncFlowWorkflow } from '../../../../shared/domain/workflows/types.no-deps'
import {TaskIcon,TrainingIcon,GuideIcon,TileIcon, RowIcon} from './icons'
type TileProps = {
    flow: SyncFlowWorkflow,
    image?: string,
    onClick: Function,
    count?: number,
    buttons?: React.ReactNode[]
}
export function RenderTile(props: TileProps) {
    if (props.image)
        var image = (
            <img
                src={props.image}
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null // prevents looping
                    currentTarget.src = './question-mark-grey.svg'
                    currentTarget.className = 'icon'
                }}
            ></img>
        )
    else var image = <RenderFlowIcon flow={props.flow}/>
    return (
        <div
            className="tile"
        >
            {props.buttons ? <div className="tile-buttons">{props.buttons}</div> : ""}
            <div className="coverimage" onClick={(e) => props.onClick(e)}>{image}</div>
            <div className="info">
                <div className="info-row title" onClick={(e) => props.onClick(e)}>{props.flow.name}</div>
                {props.count !== undefined ?
                    <div className="info-row">
                        <div className="count">
                            <div>{props.count}</div>
                        </div>
                        instances
                    </div> : <div className="info-row">
                        <sub>Version {props.flow.numberOfVersions}</sub>
                    </div>
                }
                
            </div>
        </div>
    )
}
type FlowIconProps = {
    flow: SyncFlowWorkflow
}

function RenderFlowIcon(props: FlowIconProps) {
    return props.flow.type === 'task' ?
            <div className='flowtype task'>
                <TaskIcon/>
            </div> : props.flow.type === 'training' ?
                <div className='flowtype training'>
                   <TrainingIcon/>
                </div> :
                <div className='flowtype guide'>
                    <GuideIcon/>
                </div>
}

type RowProps = {
    flow: SyncFlowWorkflow,
    image?: string,
    count?: number,
    buttons: React.ReactNode[]
}
export function RenderRow(props: RowProps) {
    return <li className={props.flow.type}>
        <RenderFlowIcon flow={props.flow}/>
        {props.count ? <div className="count">
            <div>{props.count}</div>
        </div> : ""}
        <p>
            {props.flow.name}
            <sub>Version {props.flow.numberOfVersions}</sub>
        </p>
        {props.buttons}
    </li>
}
type DisplayModeTypes = {
    viewMode: 'tile' | 'row',
    onChange: Function
}
export function DisplayModeToggle(props: DisplayModeTypes) {
    return <div className="header-buttons">
        <button onClick={(e) => { props.onChange('tile') }} className={props.viewMode == 'tile' ? 'selected' : ''}>
            <TileIcon/>
        </button>
        <button onClick={(e) => { props.onChange('row') }} className={props.viewMode == 'row' ? 'selected' : ''}>
            <RowIcon/>
        </button>
    </div>
}