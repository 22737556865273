import {
    updates,
    UpdateUpdaterProps,
    unitQuestionArray,
    DuplicateFlow,
    UpdateWizardProps,
    UpdateMappingsTypes,
} from '../../types'
import { useEffect, useState } from 'react'
import type { SyncFlowWorkflow } from '../../../../shared/domain/workflows/types.no-deps'
import { Checkbox, Button, FormControl, TextField } from '@mui/material'
import { EditEndpoint } from '../../../../server/http/workflows/edit.no-deps'
import { GetAllEndpoint as UnitTypesOutput } from '../../../../server/http/unitTypes/getAll.no-deps'
import { MapUnitType } from './CopyWizard'
import { updateText, replaceImages, findUnitQuestions, setUnitTypeId, updateFlow, findElement } from './logic'
import { EyeIcon } from './icons'
function UpdateWizard(props: UpdateWizardProps) {
    const [fromUnitTypes, setFromUnitTypes] = useState({
        status: 'loading',
        unitTypes: [] as UnitTypesOutput['Output']['unitTypes'],
    })
    const [selectedFlows, setSelectedFlows] = useState(props.selectedFlows as DuplicateFlow[])
    const [currentPage, setCurrentPage] = useState('mapper' as 'mapper' | 'update')
    const [uploadedFlows, setUploadedFlows] = useState([] as DuplicateFlow[])
    useEffect(() => {
        fetch('/getUnitTypes?customerId=' + props.selectedFlow.customerId)
            .then((response) => response.text())
            .then((data) => setFromUnitTypes(JSON.parse(data)))
    }, [])

    if (fromUnitTypes.status === 'loading')
        return (
            <div className="ViewFlows">
                <div className="pulseLoader"></div>
            </div>
        )
    if (currentPage === 'update') {
        return (
            <div className="ViewFlows">
                {selectedFlows.map((sf) => {
                    var instance = props.instances.find((instance) => instance.customerId == sf.customerId)
                    if (!instance) return <div>Error</div>
                    sf.updated =
                        uploadedFlows.filter((uf) => uf.customerId === sf.customerId && uf.flow.id === sf.flow.id)
                            .length > 0
                    return (
                        <div className="ProcedureSection success">
                            <UpdateFlows
                                updated={(updated: DuplicateFlow) => {
                                    updated.updated =
                                        uploadedFlows.filter(
                                            (uf) =>
                                                uf.customerId === updated.customerId && uf.flow.id === updated.flow.id
                                        ).length > 0
                                    if (!updated.updated) {
                                        uploadedFlows.push(updated)
                                        setUploadedFlows([...uploadedFlows])
                                    }
                                }}
                                instance={instance}
                                selectedFlow={sf}
                            />
                        </div>
                    )
                })}
                {selectedFlows.length !== uploadedFlows.length ? (
                    <Button className='big-button' variant="contained" onClick={() => { }} disabled={true}>
                        Uploading
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        className='big-button'
                        onClick={() => {
                            window.location.reload()
                        }}
                        disabled={false}
                    >
                        Done
                    </Button>
                )}
            </div>
        )
    }
    return (
        <div className="ViewFlows">
            {selectedFlows.map((selectedFlow) => {
                const fromInstance = props.instances.find(
                    (instance) => instance.customerId === props.selectedFlow.customerId
                )
                const toInstance = props.instances.find((instance) => instance.customerId === selectedFlow.customerId)
                if (!fromInstance || !toInstance) return <div>Error</div>
                return (
                    <UpdateMappings
                        returnFlow={(workflow: DuplicateFlow, valid: boolean) => {
                            workflow.updated = valid
                            setSelectedFlows(
                                selectedFlows.map((sf) => {
                                    if (
                                        sf.customerId === selectedFlow.customerId &&
                                        sf.flow.id === selectedFlow.flow.id
                                    )
                                        return workflow
                                    return sf
                                })
                            )
                        }}
                        fromInstance={fromInstance}
                        toInstance={toInstance}
                        fromUnitTypes={fromUnitTypes.unitTypes}
                        fromFlow={props.selectedFlow}
                        toFlow={selectedFlow}
                        originalToFlow={props.selectedFlows.find(
                            (sf) => sf.customerId === selectedFlow.customerId && sf.flow.id === selectedFlow.flow.id
                        )}
                    />
                )
            })}
            <Button
                variant="contained"
                className='big-button'
                onClick={() => {
                    setCurrentPage('update')
                }}
                disabled={selectedFlows.filter((sf) => sf.updated !== true).length > 0}
            >
                Update
            </Button>
        </div>
    )
}

function UpdateMappings(props: UpdateMappingsTypes) {
    const [toUnitTypes, setToUnitTypes] = useState({
        status: 'loading',
        unitTypes: [] as UnitTypesOutput['Output']['unitTypes'],
    })
    const [newWorkflow, setNewWorkflow] = useState<updates>({
        name: props.toFlow.flow.name,
        unitQuestions: props.toFlow.flow.flow ? findUnitQuestions(props.toFlow.flow.flow.steps, []) : [],
        procedureId: props.toFlow.flow.procedure?.id,
        keepText: false,
        flow: props.toFlow.flow,
    })

    useEffect(() => {
        fetch('/getUnitTypes?customerId=' + props.toFlow.customerId)
            .then((response) => response.text())
            .then((data) => {
                setToUnitTypes(JSON.parse(data))
            })
    }, [])

    newWorkflow.unitQuestions = newWorkflow.unitQuestions.map((uq) => {
        if (uq.newUnitTypeId) return uq
        // Pre-set unit type for matching questions
        if (props.toFlow.flow.flow?.steps)
            var matchedQuestion = findElement(props.toFlow.flow.flow?.steps, uq.questionId)

        if (matchedQuestion && matchedQuestion.type === 'unit-select')
            uq.newUnitTypeId = matchedQuestion.unitTypes.pop()

        if (matchedQuestion && matchedQuestion.type === 'loop') uq.newUnitTypeId = matchedQuestion.unitType

        return uq
    })

    // Check if all fields are filled
    const isComplete =
        newWorkflow.name &&
        newWorkflow.name.length > 0 &&
        newWorkflow.procedureId &&
        newWorkflow.unitQuestions.filter((uq) => !uq.newUnitTypeId).length === 0

    if (isComplete && newWorkflow.procedureId) {
        var workflow = JSON.parse(JSON.stringify(props.fromFlow)) as DuplicateFlow
        workflow.flow.name = newWorkflow.name
        workflow.flow.id = props.toFlow.flow.id
        workflow.customerId = props.toFlow.customerId
        workflow.flow.procedure = props.toFlow.flow.procedure
        if (!workflow.flow.flow || !props.toFlow.flow.flow) return <div>Error</div>
        workflow.flow.flow.steps = updateFlow(workflow.flow.flow.steps, newWorkflow)
        if (newWorkflow.keepText && props.originalToFlow && props.originalToFlow.flow.flow)
            if (workflow?.flow?.flow?.steps) {
                updateText(workflow.flow.flow.steps, props.originalToFlow.flow.flow.steps)
            } else {
                console.error('Steps not found')
            }
        if (props.toFlow.flow.flow) workflow.flow.flow.subjectType = props.toFlow.flow.flow.subjectType
        if (JSON.stringify(props.toFlow.flow.flow) !== JSON.stringify(workflow.flow.flow) || !props.toFlow.updated) {
            props.returnFlow(workflow, true)
        }
    }

    if (toUnitTypes.status === 'loading')
        return (
            <div className="ViewFlows">
                <div className="pulseLoader"></div>
            </div>
        )
    function getQuestionLabel(question: unitQuestionArray) {
        switch (question.questionType) {
            case 'loop':
                return 'Loop Element: ' + question.questionLabel
            case 'unit-select':
                return 'Unit Select Element: ' + question.questionLabel
            case 'subject':
                return question.questionLabel
            default:
                return 'Unit Filter For: ' + question.questionLabel
        }
    }

    return (
        <div className="ProcedureSection">
            <div className="row">
                <div className="column">
                    <h2>
                        Copy from <b>{props.fromInstance.customerName}</b> to...
                    </h2>
                </div>
                <div className="column">
                    <h2>
                        <b>{props.toInstance.customerName}</b>
                    </h2>
                </div>
            </div>
            <div className="row">
                <div className="column">
                    <p>
                        Name Flow
                        <sub>Name must be unique to the procedure</sub>
                    </p>
                </div>
                <div className="column">
                    <FormControl fullWidth>
                        <TextField
                            onChange={(event) => {
                                setNewWorkflow({
                                    unitQuestions: newWorkflow.unitQuestions,
                                    name: event.target.value,
                                    procedureId: newWorkflow.procedureId,
                                    keepText: newWorkflow.keepText,
                                    flow: newWorkflow.flow,
                                })
                            }}
                            value={newWorkflow.name}
                            variant="outlined"
                        />
                    </FormControl>
                </div>
            </div>
            {newWorkflow.unitQuestions.map((unitQuestion) => {
                return (
                    <MapUnitType
                        fromUnit={
                            props.fromUnitTypes
                                ? props.fromUnitTypes.find((unitType) => {
                                      return unitType.id === unitQuestion.unitTypeId
                                  })
                                : undefined
                        }
                        toUnitTypes={toUnitTypes.unitTypes}
                        prompt={getQuestionLabel(unitQuestion)}
                        handleChange={(event: any) => {
                            setNewWorkflow({
                                unitQuestions: setUnitTypeId(event, unitQuestion, newWorkflow),
                                name: newWorkflow.name,
                                procedureId: newWorkflow.procedureId,
                                keepText: newWorkflow.keepText,
                                flow: newWorkflow.flow,
                            })
                        }}
                        preselectUnitType={unitQuestion.newUnitTypeId}
                    />
                )
            })}
            <div className="row">
                <div className="column">
                    <p>
                        Keep translations?
                        <sub>Check this box to keep any translations</sub>
                    </p>
                </div>
                <div className="column">
                    <FormControl fullWidth>
                        <Checkbox
                            onChange={(event) => {
                                setNewWorkflow({
                                    unitQuestions: newWorkflow.unitQuestions,
                                    name: newWorkflow.name,
                                    procedureId: newWorkflow.procedureId,
                                    keepText: event.target.checked,
                                    flow: newWorkflow.flow,
                                })
                            }}
                            value={newWorkflow.keepText}
                        />
                    </FormControl>
                </div>
            </div>
        </div>
    )
}

export function UpdateFlows(props: UpdateUpdaterProps) {
    const [updatedWorkflow, setUpdatedWorkflow] = useState({
        status: props.selectedFlow.updated ? 'success' : 'loading',
        response: {} as EditEndpoint['Output'],
    })
    useEffect(() => {
        if (!props.selectedFlow.updated)
            replaceImages(props.instance, props.selectedFlow.flow).then((newFlow: SyncFlowWorkflow) => {
                fetch('/updateFlow?customerId=' + props.instance.customerId, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newFlow),
                })
                    .then((response) => response.text())
                    .then((data) =>
                        setUpdatedWorkflow(JSON.parse(data) as { status: string; response: EditEndpoint['Output'] })
                    )
            })
    }, [])
    if (updatedWorkflow.status === 'loading') return <div className="pulseLoader"></div>
    if (updatedWorkflow.status === 'fail' || !updatedWorkflow.response) return <div>An error has occured</div>
    // @ts-ignore
    if (updatedWorkflow.response && updatedWorkflow.response.message == 'User is not a collaborator')
        return <li>Missing collaborator or procedure admin permission</li>

    props.updated(props.selectedFlow)

    return (
        <li>
            <p>
                ✅ {props.selectedFlow.flow.name}
                <sub>
                    Flow updated successfully! Now you can add schedules, statuses, filters and other instance level
                    features by pressing the preview icon
                </sub>
            </p>
            {props.selectedFlow.flow.procedure && props.selectedFlow.flow.procedure.id ? (
                <a
                    rel="noreferrer"
                    href={
                        'https://' +
                        props.instance.serverURL +
                        '/procedures/' +
                        props.selectedFlow.flow.procedure.id +
                        '/tasks/workflows/' +
                        props.selectedFlow.flow.id +
                        '/edit'
                    }
                    target="_blank"
                >
                    <button><EyeIcon/></button>
                </a>
            ) : (
                ''
            )}
        </li>
    )
}
export default UpdateWizard
