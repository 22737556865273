import { useState } from 'react'
import { MentionsInput, Mention } from 'react-mentions'
import './calculation.css'
import { Step, Element, ElementId, StepId, ConcreteStep } from '../../../../shared/domain/flows/types.no-deps'
import {
    Expression,
    ElementFilterNot,
    ElementFilterAnd,
    ElementFilterOr,
    ElementInList,
    ElementInSteps,
    ElementFilter,
    Aggregate,
    ElementsQuery,
} from '../../../../shared/domain/flows/Expression.no-deps'

export type CalculationProps = {
    unitAttributes: string[]
    questions: Element[]
    returnExpression: (expression: Expression, valid: Boolean) => void
    expression: Expression
    steps: ConcreteStep[]
}
function Calculation(props: CalculationProps) {
    const [value, setValue] = useState(formulaToText(props.expression, props.questions, props.steps))
    const supportedElements = ['yes-no', 'number', 'confirmation', 'dropdown', 'datetime']
    props.steps.forEach(step => {
        step.label = step.label.replaceAll(',','‚').replaceAll('!','ǃ')
    })
    props.questions.forEach(question => {
        question.label = question.label.replaceAll(',','‚').replaceAll('!','ǃ')
    })
    const formula = covertToFormula(value)
    props.returnExpression(formula.expression, !formula.has_errors)
    type formulaConversion = {
        expression: Expression
        has_errors: boolean
        errors: string[]
    }
    function formulaToText(expression: Expression, elements: Element[], steps: Step[]): string {
        if (expression.type === 'constant') return expression.constant + ''

        if (expression.type === 'element') {
            const element = elements.find((element) => element.id === expression.elementId)
            if (element) return ' @[' + element.label + ']{' + expression.elementId + '} '
            else return ' @[MISSING ELEMENT]{' + expression.elementId + '} '
        }

        if (expression.type === 'unit-data-variable') {
            return ' #[' + expression.variableName + ']{' + 1 + '} '
        }

        if (expression.type === 'operator') {
            // If operator is a * or /, add brackets to protect BIDMAS
            return (
                (expression.left.type === 'operator' && (expression.operator === '*' || expression.operator === '/')
                    ? '('
                    : '') +
                formulaToText(expression.left, elements, steps) +
                (expression.left.type === 'operator' && (expression.operator === '*' || expression.operator === '/')
                    ? ') '
                    : '') +
                expression.operator +
                ' ' +
                (expression.right.type === 'operator' && (expression.operator === '*' || expression.operator === '/')
                    ? '('
                    : '') +
                formulaToText(expression.right, elements, steps) +
                (expression.right.type === 'operator' && (expression.operator === '*' || expression.operator === '/')
                    ? ') '
                    : '')
            )
        }
        const buildFilterAsText = (filter: ElementFilter): string => {
            if (filter.type === 'element-in-list')
                return (
                    'elementIs(' +
                    filter.list
                        .map((id) => {
                            const element = elements.find((element) => element.id === id)
                            if (element) return ' @[' + element.label + ']{' + id + '} '
                            else return ' @[MISSING ELEMENT]{' + id + '} '
                        })
                        .join(' , ') +
                    ')'
                )

            if (filter.type === 'element-in-steps')
                return (
                    'elementIn(' +
                    filter.list.map((id) => {
                        const step = steps
                            .map((step, index) => {
                                if (step.type !== 'placeholder' && step.label.trim() === '')
                                    step.label = 'Step ' + (index + 1)
                                return step
                            })
                            .find((step) => step.id === id)
                        if (step && step.type !== 'placeholder') return ' ![' + step.label + ']{' + id + '} '
                        else return ' ![MISSING STEP]{' + id + '} '
                    }) +
                    ')'
                )

            if (filter.type === 'element-has-type') {
                return 'elementTypeIs(' + filter.elementType + ')'
            }
            if (filter.type === 'element-has-answer') {
                return 'elementHasAnswer()'
            }
            if (filter.type === 'element-filter-and')
                return buildFilterAsText(filter.left) + ' and ' + buildFilterAsText(filter.right)

            if (filter.type === 'element-filter-or')
                return buildFilterAsText(filter.left) + ' or ' + buildFilterAsText(filter.right)

            if (filter.type === 'element-filter-not') return 'not ' + buildFilterAsText(filter.operand)

            return ''
        }
        if (expression.type === 'aggregate') {
            return (
                expression.aggregateFunction +
                '(' +
                expression.query.select.replace('max-possible-value', 'elementMaxes').replace('min-possible-value', 'elementMins').replace('value','answer') +
                (expression.query.where !== undefined
                    ? ' , ' + buildFilterAsText(expression.query.where as ElementFilter)
                    : '') +
                ')'
            )
        }
        return ''
    }
    function buildFilter(text: string): {
        error: string | null
        expression: ElementFilter | null
    } {
        text = text.trim().toLowerCase()
        const function_name_regex = /^[a-z]*\(.*\)\s*$/
        const get_function_name = /^[a-z]*(?=\()/
        const get_question_regex =
            /\@\[(.*?)\]{[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\}/g
        const get_udid_regex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/
        const get_text_in_brackets = /(?<=\()([^)]*)(?=\))/
        const get_step_regex =
            /\!\[[^\]]*\]\{[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\}/g
        const get_andor_regex = /(?<!\([^)]*)\b(and|or|not)\b(?![^(]*\))/

        const andor_check = text.match(get_andor_regex)
        const function_name_match = text.match(get_function_name)
        const supported_functions = ['elementin', 'elementtypeis', 'elementhasanswer', 'elementis']

        if (andor_check && andor_check.index !== undefined) {
            // Support for and/or/not logic
            const left = buildFilter(text.slice(0, andor_check.index))
            const right = buildFilter(text.slice(andor_check.index + andor_check[0].length))
            var operator: ElementFilterAnd['type'] | ElementFilterOr['type'] | ElementFilterNot['type']
            if (andor_check[0] === 'not' && andor_check.index === 0 && !right.error && right.expression !== null)
                return {
                    expression: {
                        type: 'element-filter-not',
                        operand: right.expression,
                    } as ElementFilterNot,
                    error: null,
                }

            if (left.error || right.error || left.expression === null || right.expression === null)
                return {
                    expression: null,
                    error: left.error || right.error,
                }
            if (andor_check[0] === 'and')
                return {
                    expression: {
                        left: left.expression,
                        right: right.expression,
                        type: 'element-filter-and',
                    },
                    error: null,
                }
            if (andor_check[0] === 'or')
                return {
                    expression: {
                        left: left.expression,
                        right: right.expression,
                        type: 'element-filter-or',
                    },
                    error: null,
                }
        }
        console.log("Filter text",text,function_name_match)
        if (!text.match(function_name_regex) || !function_name_match)
            return {
                expression: null,
                error: 'Syntax error in filter: ' + text,
            }

        const functionName = function_name_match[0]
        if (supported_functions.indexOf(functionName) === -1)
            return {
                expression: null,
                error: 'Function ' + functionName + ' is not supported',
            }

        if (functionName === 'elementis') {
            const elements = text.match(get_question_regex)

            if (!elements || elements.length == 0)
                return {
                    expression: null,
                    error: 'Elements In function must have at least 1 element',
                }
            return {
                error: null,
                expression: {
                    type: 'element-in-list',
                    list: elements
                        .map((element) => {
                            const udid = element.match(get_udid_regex)
                            if (!udid) return
                            return udid[0] as ElementId
                        })
                        .filter((element) => element !== undefined),
                } as ElementInList,
            }
        }

        if (functionName === 'elementtypeis') {
            const type = text.match(get_text_in_brackets)
            if (!type || !type[0] || supportedElements.indexOf(type[0]) === -1) {
                return {
                    expression: null,
                    error: 'Type is function must specify one of: ' + supportedElements.join(','),
                }
            }
            return {
                expression: {
                    type: 'element-has-type',
                    elementType: type[0] as Element['type'],
                },
                error: null,
            }
        }

        if (functionName === 'elementhasanswer') {
            return {
                expression: {
                    type: 'element-has-answer',
                },
                error: null,
            }
        }

        if (functionName === 'elementin') {
            const steps = text.match(get_step_regex)
            if (!steps)
                return {
                    expression: null,
                    error: 'No steps in elementin function, type ! to select 1 or more steps',
                }
            return {
                error: null,
                expression: {
                    type: 'element-in-steps',
                    list: steps
                        .map((step) => {
                            const udid = step.match(get_udid_regex)
                            if (!udid) return
                            return udid[0] as StepId
                        })
                        .filter((step) => step !== undefined),
                } as ElementInSteps,
            }
        }
        return {
            expression: null,
            error: 'Syntax error in filter: ' + text,
        }
    }
    function parseFunction(text: string): {
        error: string | null
        formula: Aggregate | null
    } {
        console.log('parse function', text)
        const function_name_regex = /^[a-z]+\(.*\)$/
        const get_function_name = /^[a-z]+(?=\()/
        const supported_functions = [
            'count',
            'sum',
            'product',
            'mean',
            'median',
            'mode',
            'maximum',
            'minimum',
            'range',
            'stddev',
        ]
        const get_function_values_regex = /(?<=\().*(?=\))/
        const get_comma_not_in_brackets = /,(?![^(]*\))(?![^{]*\})/g
        text = text.toLowerCase().trim()
        const functionMatch = text.match(function_name_regex)
        if (!functionMatch)
            return {
                formula: null,
                error: null,
            }
        const full_function = functionMatch[0]

        const function_name_match = full_function.match(get_function_name)

        if (!function_name_match || supported_functions.indexOf(function_name_match[0].toLowerCase().trim()) === -1)
            return {
                formula: null,
                error: function_name_match
                    ? 'Unknown Function: ' + function_name_match[0].trim()
                    : 'Not a valid function',
            }

        const functionVariables = full_function.match(get_function_values_regex)
        if (!functionVariables)
            return {
                formula: null,
                error: 'Missing Paramaters for function ' + function_name_match[0].trim(),
            }

        const functionParamaters = functionVariables[0]
            .split(get_comma_not_in_brackets)
            .filter((paramater) => paramater.length > 0)
        if (functionParamaters.length > 2)
            return {
                formula: null,
                error: 'Function ' + function_name_match[0].trim() + ' only supports up to 2 arguments',
            }
        const selectTypes = ['answer', 'elementmaxes', 'elementmins']

        // If no paramaters default to value
        if (functionParamaters.length == 0) functionParamaters.push('answer')
        // If only 1 paramater which is not an element filter, add element filter
        else if (functionParamaters.length == 1 && selectTypes.indexOf(functionParamaters[0]) === -1)
            functionParamaters.unshift('answer')

        // If only 1 paramater which is an element filter, return expression with blank element filter
        if (functionParamaters.length == 1 && selectTypes.indexOf(functionParamaters[0]) > -1) {
            return {
                formula: {
                    query: {
                        type: 'elements-query',
                        select: functionParamaters[0]
                            .replace('elementmaxes', 'max-possible-value')
                            .replace('elementmins', 'min-possible-value') as ElementsQuery['select'],
                    },
                    aggregateFunction: function_name_match[0] as Aggregate['aggregateFunction'],
                    type: 'aggregate',
                    evaluateNullAs: 0,
                },
                error: null,
            }
        }

        // If 2 paramaters build expression
        const elementFilter = buildFilter(functionParamaters[1])
        if (elementFilter.error)
            return {
                formula: null,
                error: elementFilter.error,
            }

        if (elementFilter.expression)
            return {
                formula: {
                    query: {
                        type: 'elements-query',
                        select: functionParamaters[0]
                            .replace('answer', 'value')
                            .replace('elementmaxes', 'max-possible-value')
                            .replace('elementmins', 'min-possible-value') as ElementsQuery['select'],
                        where: elementFilter.expression,
                    },
                    aggregateFunction: function_name_match[0] as Aggregate['aggregateFunction'],
                    type: 'aggregate',
                },
                error: null,
            }

        return {
            formula: null,
            error: 'Unable to understand: ' + text,
        }
    }
    function getOperator(text: string) {
        // Programatically find operator not in brackets
        // (regex not great for this kind of check)
        const operatorIndices: number[] = []
        let bracketLevel = 0

        for (let i = 0; i < text.length; i++) {
            const char = text[i]

            if (char === '(' || char === '[' || char === '{') {
                bracketLevel++
            } else if (char === ')' || char === ']' || char === '}') {
                bracketLevel--
            } else if (bracketLevel === 0 && ['+', '-', '*', '/'].includes(char)) {
                return i // Operator at top level
            }
        }

        return -1
    }
    function matchBalancedParentheses(input: string) {
        const matches: string[] = []
        let depth = 0
        let currentMatch = ''

        for (let i = 0; i < input.length; i++) {
            const char = input[i]

            if (char === '(') {
                if (depth > 0) {
                    currentMatch += char
                }
                depth++
            } else if (char === ')') {
                depth--
                if (depth > 0) {
                    currentMatch += char
                } else if (depth === 0) {
                    matches.push(currentMatch)
                    currentMatch = ''
                }
            } else if (depth > 0) {
                currentMatch += char
            }
        }

        return matches
    }
    function covertToFormula(text: string): formulaConversion {
        const cleanedText = text.trim()
        const get_question_regex =
            /\@\[(.*?)\]{[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\}/
        const get_step_regex = /\!\[(.*?)\]{[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}\}/
        const get_attribute_regex = /\#\[(.*?)\]{\d*}/
        const get_attribute_value_regex = /[^\[\]]+(?=\])/
        const get_udid_regex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/
        const operator_check = getOperator(cleanedText)
        if (operator_check > -1) {
            var operator = cleanedText.slice(operator_check, 1)
            if (operator !== null && operator_check) {
                const leftHand = covertToFormula(cleanedText.slice(0, operator_check))
                const rightHand = covertToFormula(cleanedText.slice(operator_check + 1))
                return {
                    expression: {
                        operator: operator[0] as '*' | '/' | '+' | '-',
                        left: leftHand.expression,
                        right: rightHand.expression,
                        type: 'operator',
                    },
                    has_errors: false || leftHand.has_errors || rightHand.has_errors,
                    errors: leftHand.errors.concat(rightHand.errors),
                }
            }
        }

        // If number return as constant
        if (/^-?\d+(\.\d+)?$/.test(cleanedText)) {
            return {
                expression: {
                    type: 'constant',
                    constant: parseFloat(cleanedText),
                },
                has_errors: false,
                errors: [],
            }
        }

        const functionCheck = parseFunction(cleanedText)

        if (functionCheck.formula) {
            return {
                expression: functionCheck.formula,
                has_errors: false,
                errors: [],
            }
        } else if (functionCheck.error) {
            return {
                expression: {
                    constant: 1,
                    type: 'constant',
                },
                has_errors: true,
                errors: [functionCheck.error],
            }
        }

        const element_check = cleanedText.match(get_question_regex)
        if (element_check) {
            //Element
            const element = element_check[0]
            const elementId = element.match(get_udid_regex)
            if (elementId)
                return {
                    expression: {
                        type: 'element',
                        elementId: elementId[0] as ElementId,
                    },
                    has_errors: false,
                    errors: [],
                }
        }

        const step_check = cleanedText.match(get_step_regex)
        if (step_check) {
            //If step found, throw error
            return {
                expression: {
                    type: 'constant',
                    constant: 1,
                },
                has_errors: true,
                errors: ['Steps can only be used inside an inStep function'],
            }
        }

        const attribute_check = cleanedText.match(get_attribute_regex)
        if (attribute_check) {
            //Unit Attribute
            const attribute = attribute_check[0]
            const attributeValue = attribute.match(get_attribute_value_regex)
            if (attributeValue)
                return {
                    expression: {
                        type: 'unit-data-variable',
                        variableName: attributeValue[0],
                    },
                    has_errors: false,
                    errors: [],
                }
        }

        const brackets_check = matchBalancedParentheses(cleanedText)
        if (brackets_check && brackets_check.length > 0) {
            return covertToFormula(brackets_check[0])
        }

        return {
            expression: {
                constant: 1,
                type: 'constant',
            },
            has_errors: true,
            errors: [cleanedText],
        }
    }
    var cursorPosition = 0
    return (
        <>
            <div className="formula">
                <MentionsInput
                    allowSpaceInQuery={true}
                    className={formula.has_errors ? 'mentions error' : 'mentions'}
                    value={value}
                    onChange={(e: any) => {
                        setValue(e.target.value)
                    }}
                >
                    <Mention
                        trigger={/(@([^@]*))$/}
                        appendSpaceOnAdd={true}
                        markup="@[__display__]{__id__}"
                        data={props.questions.map((question, index) => {
                            return {
                                id: question.id,
                                display: question.label,
                            }
                        })}
                    />
                    <Mention
                        trigger={/(#([^#]*))$/}
                        className={'attribute'}
                        markup="#[__display__]{__id__}"
                        appendSpaceOnAdd={true}
                        data={props.unitAttributes.map((attribute, index) => {
                            return {
                                id: index,
                                display: attribute,
                            }
                        })}
                    />
                    <Mention
                        trigger={/(!([^!]*))$/}
                        appendSpaceOnAdd={true}
                        className={'step'}
                        markup="![__display__]{__id__}"
                        data={props.steps.map((step, index) => {
                            return {
                                id: step.id,
                                display: step.label ? step.label : 'Step ' + (index + 1),
                            }
                        })}
                    />
                </MentionsInput>
                <div className="errors">
                    {formula.errors.map((error) => {
                        if (error && error.trim().length > 0)
                            return <sub>Error: {error.replaceAll(/(?<=[@!#])\[|\]{[^}]*}/g, '')}</sub>
                    })}
                </div>
            </div>
        </>
    )
}

export default Calculation
