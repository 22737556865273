import { useEffect, useState } from 'react'
import './App.css'
import './ViewFlows'
import ViewFlows from './ViewFlows'
import HomePage from './HomePage'
import type { accountType } from '../../types'
import {DeleteIcon} from './icons'
import {
    TextField
} from '@mui/material'
function App() {
    const [message, setMessage] = useState([] as accountType[])
    const [selectedInstance, setInstance] = useState<accountType | null>()
    const [search, setSearch] = useState<string>('')
    useEffect(() => {
        fetch('/list')
            .then((response) => response.text())
            .then((data) => {const accounts=JSON.parse(data); setMessage(accounts);return accounts as accountType[]})
            .then((accounts : accountType[]) => {
                const query = new URLSearchParams(window.location.search);
                const customerId = query.get('customerId')
                if (customerId && !isNaN(parseInt(customerId))) {
                    const account = accounts.find(account => parseInt((account.customerId as unknown) as string) === parseInt(customerId))
                    console.log('account',account)
                    if(account)
                        setInstance(account)
                }
            })
        
        
    }, [])

    var accounts
    if (!message) accounts = [] as accountType[]
    else accounts = message
    accounts = accounts.map(account => {
        account.isLibrary = account.customerSlug.indexOf('global') > -1 || account.customerSlug.indexOf('library') > -1 || account.customerName.toLowerCase().indexOf('library') > -1 || account.customerName.toLowerCase().indexOf('global') > -1;
        return account; 
    }).sort((a , b) => {
        if (!a.isLibrary || !b.isLibrary)
            return 0;
        if (a.isLibrary > b.isLibrary) {
            return 1;
        }
        if (a.isLibrary < b.isLibrary) {
            return -1;
        }
        return 0;
    })
    return (
        <div className="App">
            <header className="App-header">
                <a onClick={() => setInstance(undefined)}>
                    <div className="home-icon">
                        <img src='./logo.png'/>
                    </div>
                </a>
                <p>{selectedInstance ? selectedInstance.customerName : 'Library'}</p>
            </header>
            <body className="App-body">
                {accounts.length > 0 ? (
                    <div className="left-panel">
                        <TextField label={"Search " + accounts.length + " Connections"} className="search" variant="standard" onChange={(e : any) => {setSearch(e.target.value)}} placeholder="Search"/>
                        <a href="/add">
                            <div className="add-new">Add</div>
                        </a>
                        {accounts
                            .sort(function (a, b) {
                                var aname = (a.isLibrary ? 'a' : 'b') + a.customerName.toLowerCase();
                                var bname = (b.isLibrary ? 'a' : 'b') + b.customerName.toLowerCase();
                                if (aname < bname) {
                                    return -1
                                }
                                if (aname > bname) {
                                    return 1
                                }
                                return 0
                            })
                            .filter((server) => {
                                return search === '' || server.customerName.toLowerCase().indexOf(search.toLowerCase()) > -1
                            })
                            .map((server) => (
                                <li
                                    className={(server.customerId == selectedInstance?.customerId ? 'selected ' : '') + (server.isLibrary ? 'library' : '')}
                                    onClick={() => setInstance(server)}
                                >
                                    {server.customerName}
                                    <a
                                        onClick={() => {
                                            fetch('/delete?customerId=' + server.customerId)
                                                .then((response) => response.text())
                                                .then((data) => {
                                                    setMessage(JSON.parse(data))
                                                    setInstance(null)
                                                })
                                        }}
                                    >
                                        <div className="delete">
                                            <DeleteIcon/>
                                        </div>
                                    </a>
                                </li>
                            ))}
                    </div>
                ) : (
                    ''
                )}
                <div className="right-panel" style={accounts.length === 0 ? { width: '100%' } : {}}>
                    {!selectedInstance ? (
                        accounts.length > 0 ? (
                            <HomePage instances={accounts} />
                        ) : (
                            <div>
                                <div className="ViewFlows">
                                    <div className="Error">
                                        <h1>Zaptic Library</h1>
                                        <p>Add instances or integrations to build your library</p>
                                        <a href="/add">
                                            <div className="add-new">Add</div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )
                    ) : (
                        <div key={selectedInstance.customerId}>
                            <ViewFlows account={selectedInstance} />
                        </div>
                    )}
                </div>
            </body>
        </div>
    )
}

export default App
